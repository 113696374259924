import React, { Fragment, useEffect, useState } from 'react'
import { Grid, Typography, CardContent, Button, Container, Card, CardHeader } from '@mui/material';
import StatusMessage from '../StatusMessage';
import CardStatusTransaction from '../CardStatusTransaction';
import AssistComponent from '../AssistComponent';
import { AddCircle, Info, Receipt } from '@mui/icons-material';
import PurchaseDetail from '../PurchaseDetail';
import SecureSite from '../SecureSite';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CONFIRMED } from './utils';
import { connect } from 'react-redux';
import { resetReservationAction } from '../../../../store/actions';
import { useTranslation } from 'react-i18next';
import { useClientData } from '../../../../context/ClientContext';
import { getPayment } from '../../../../store/services/Login';
import CardDetail from '../CardDetail';

const Confirmed = (props) => {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { clientData, setPayment, setClientData } = useClientData();
  const [content, setContent] = useState({});
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    let { content,  messages } = CONFIRMED[params.module] || { content: {}, messages: [] };

    if (location.state?.dataFinal?.change_price) {
      const messagesAux = content.messages || [];
      messagesAux.push({
        title: '¡Atención!',
        subtitle: t('checkout.common.changePriceReservation'),
        className: 'status-pending'
      });
      content.messages = messagesAux;
    }

    if (props.bookingStatus) {
      if (props.bookingStatus?.emited) {
        content.status_booking_icon = 'check_circle';
        content.status_booking_title = 'requestedIssue';
        content.status_booking = 'ok';
      }
      if (props.bookingStatus?.payment) {
        content.show_status_payment = true;

        if (props.paymentData?.name === 'credit') {
          content.status_payment_title = 'approvedCredit';
        }

        messages[0].message = ['sucessBookingAndPayment'];
      }
    }

    setContent(content);
    setMessages(messages);
    getPaymentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CardContentStatusTransaction = () => (
    <CardStatusTransaction
      reservationNumber={params.reservationNumber}
      content={content}
    />
  )

  const DescriptionMessage = () => {
    return (
      messages && messages.length > 0 && messages.map((item, i) => {
        return (
          <Fragment key={i}>
            {item.position === "descriptionMessage" &&
              item.message.map((item, i) => {
                return (<Fragment key={i} >
                  <CardContent className="text-center card-divider">
                    <div className="content card-content-booking">
                      <div dangerouslySetInnerHTML={{ __html: t(`checkout.thankYouPage.${item}`) }} />
                    </div>
                  </CardContent>
                </Fragment>)
              })}
          </Fragment>
        )
      })
    )
  }

  const CardContentMessage = () => {
    return (
      <Grid>
        <DescriptionMessage />
        {props.email && <CardContent className="text-center card-divider">
          <div className="content card-content-booking">
            <Typography>{t('checkout.thankYouPage.receiveReservationTo')} <strong>{props.email}</strong></Typography>
            <Typography>{t('checkout.thankYouPage.spam')}</Typography>
          </div>
        </CardContent>}
      </Grid>
    )
  }

  const handleClickReservations = () => {
    if (params.idTransaction) {
      // let adminUrl = `https://admin-b2.e2etravelsolutions.com/#/reservation/${params.idTransaction}/show`;  // PROD
      let adminUrl = `https://dev-b2-admin.dev001.api-services-group.com/#/reservation/${params.idTransaction}/show`;  // DEV
      const token = localStorage.getItem('jwt');
      if (token) {
        adminUrl += `?token=${token}`;
      }
      window.open(adminUrl);
    }
  }

  const getRedirectUrl = () => {
    const module = clientData?.modules && Array.isArray(clientData?.modules) && clientData?.modules?.filter(elem => elem.module === params.module)?.slice(-1)?.[0];
    return module?.path || '/';
  }

  const handleSelectReservation = (reservationNumber = null) => {
    const reservation = props.reservations?.find(elem => elem.number === reservationNumber);
    props.handleSelectReservation && props.handleSelectReservation(reservation || null);
    if (reservationNumber && reservation) {
      localStorage.setItem('selectedReservation', JSON.stringify(reservation));
    } else {
      localStorage.removeItem('selectedReservation');
    }
    props.resetReservation();
    navigate(getRedirectUrl());
  }

  const ReservationContainer = () => {
    return <Grid className='reservation-container row mt-2'>
      <Grid className='left-side row'><Info /></Grid>
      <Grid className='right-side' container columns={24}>
        {!params.tokenIframe && (
          <Grid item xs={24} sm={8} xl={7} className='column' style={{ borderRight: '1px solid lightgray' }}>
            <Typography>
              {t('checkout.thankYouPage.bookingDetail')}
            </Typography>
            <Button startIcon={<Receipt />} color="default" variant='contained' onClick={handleClickReservations}>
              {t('checkout.thankYouPage.myBookings')}
            </Button>
          </Grid>
        )}
        <Grid item xs={24} sm={params.tokenIframe ? 12 : 9} className='column' style={{ borderRight: '1px solid lightgray' }}>
          <Typography>
            {t('checkout.thankYouPage.addProducts')}
          </Typography>
          <Button startIcon={<AddCircle />} variant='contained' onClick={() => handleSelectReservation(params.reservationNumber)}>
            {t('checkout.thankYouPage.continueBooking')}
          </Button>
        </Grid>
        <Grid item xs={24} sm={params.tokenIframe ? 12 : 7} xl={params.tokenIframe ? 12 : 8} className='column'>
          <Typography>
            {t('checkout.thankYouPage.newBooking')}
          </Typography>
          <Button startIcon={<AddCircle />} color='secondary' variant='outlined' onClick={() => handleSelectReservation()}>
            {t('checkout.thankYouPage.reserve')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  }

  const getPaymentData = async () => {
    try {
      const token = localStorage.getItem('jwt');;
      if (token) {
        const response = await getPayment(token);
        setPayment(response || {});
        localStorage.setItem('payment', JSON.stringify(response));
      }
    } catch (error) {
      const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
      if (iframePath) {
        localStorage.clear();
        setClientData(null);
        navigate(iframePath);
      } else {
        navigate('/login', { state: { expiredSession: true } });
      }
    }
  }

  const getSafeTravelComponent = () => {
    const showSafeTravel = !props.client?.checkout?.hideSafeTravelByProduct?.find(elem => elem === params.module)
      && !params.tokenIframe
      && !clientData.checkout?.hideSafeTravel;
    if (showSafeTravel) {
      return <AssistComponent />;
    }
  };

  const getCardDetail = () => {
    if (params.module === 'assists') {
      return <Card className="card-detail-final" sx={{ mt: 3 }}>
        <CardHeader
          title={t(`checkout.${params.module}.detailTitle`)}
          titleTypographyProps={{ sx: { color: '#0d47a1', fontWeight: 'bold' } }}
          sx={{ padding: 2 }}
        />
        <CardDetail
          cardDetail={location.state?.products}
          module={params.module}
          purchaseDetail={location.state?.purchaseDetail}
          isFinalView
        />
      </Card>
    }
  }

  return (
    <Container className='booking-status-ok'>
      <Grid container spacing={2}>
        <Grid item md={8} className='col-content'>
          <StatusMessage
            title={content.title}
            icon={content.icon}
            statusMessage={content.statusMessage}
            cardContentTop={<Fragment />}
            cardContentStatusTransaction={<CardContentStatusTransaction />}
            cardContentMessage={<CardContentMessage />}
          />
          {getSafeTravelComponent()}
          <ReservationContainer />
          {getCardDetail()}
        </Grid>

        {props.purchaseDetail && (
          <Grid item md={4} className="col-sidebar">
            <PurchaseDetail
              purchaseDetail={props.purchaseDetail}
              products={props.products}
              module={params.module}
            />
            <SecureSite client={props.client?.client} />
          </Grid>
        )}
      </Grid>
    </Container>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    resetReservation: () => dispatch(resetReservationAction())
  };
}

export default connect(null, mapDispatchToProps)(Confirmed);
