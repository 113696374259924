import React, { useState } from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CardCoverage from '../../../modules/Assists/CardCoverage'
import dayjs from 'dayjs';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line react-hooks/rules-of-hooks
const getProductsDetails = (purchaseDetail) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  return (
    <>
      {purchaseDetail?.priceDetail?.map((groupItem) =>
        groupItem.price_detail?.map((subItem, index) => {
          const productInfo = purchaseDetail?.products?.[index];
          const showOldPrice =
            subItem.priceOffer && parseFloat(subItem.priceOffer) > 0;
          const quantityLabel =
            productInfo?.quantity && productInfo.quantity > 1
              ? ` x ${productInfo.quantity}`
              : '';

          return (
            <Grid container className="item-resume" key={index}>
              <Grid item md={8} xl={9}>
                <div className="container-item-resume">
                  <div className="title-line">
                    {productInfo?.name} ({productInfo?.rateAge} {t('checkout.common.years')})
                    {quantityLabel}
                  </div>

                  {subItem.promoLabel && (
                    <div className="promo-line">{subItem.promoLabel}</div>
                  )}
                </div>
              </Grid>

              <Grid item md={4} xl={3}>
                <div className="text-right price-container">
                  {showOldPrice ? (
                    <>
                      <span className="old-price">
                        {`${subItem.currency} ${subItem.amount}`}
                      </span>
                      <span className="offer-price">
                        {`${subItem.currency} ${subItem.priceOffer}`}
                      </span>
                    </>
                  ) : (
                    <span className="normal-price">
                      {`${subItem.currency} ${subItem.amount}`}
                    </span>
                  )}
                </div>
              </Grid>
            </Grid>
          );
        })
      )}
    </>
  );
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const getCardDetail = (products, purchaseDetail) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [openModal, setOpenModal] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [selectedRate, setSelectedRate] = useState(null);

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedRate(null);
  };

  function parseCoverage(description) {
    const tokens = [
      'usd',
      'usd/€',
      'incluido',
      'no incluido',
      'tkt aéreo + htl',
      'tkt aereo eco',
      '75/99',
    ];

    const descLower = description.toLowerCase();
    let cutIndex = -1;

    for (const token of tokens) {
      const i = descLower.indexOf(token);
      if (i !== -1) {
        cutIndex = i;
        break;
      }
    }

    if (cutIndex === -1) {
      return [description.trim(), ''];
    }

    const left = description.substring(0, cutIndex).trim();
    const right = description.substring(cutIndex).trim();

    return [left, right];
  }

  const handleOpenCoverage = (cd) => {
    const detailProduct = cd?.detailItems?.[0]?.details?.detailProduct || [];

    const rateData = {
      name: cd?.name || 'Sin nombre',
      mediaImage: cd?.image,
      coverage: detailProduct.map((item) => {
        const [coverName, coverValue] = parseCoverage(item.description);
        return {
          name: coverName || '',
          value: coverValue || '',
          is_highlighted: false,
        };
      }),

    };

    setSelectedRate(rateData);
    setOpenModal(true);
  };

  return (
    <>
      {products?.map((product, i) => {
        const range = purchaseDetail?.products?.[i]?.rateAge ?? '0-75';
        const realRange = range.replace(/ *- */g, ' a ');

        return product.pivot?.map((pivotItem, j) => {
          const cd = pivotItem.cardDetail;
          if (!cd) return null;

          const detailSearch = cd?.detailItems?.[0]?.details?.detailSearch || [];
          const rawCheckin = purchaseDetail?.checkin;
          const rawCheckout = purchaseDetail?.checkout;

          const salida = rawCheckin
            ? dayjs(rawCheckin).format('DD/MM/YYYY')
            : '';
          const regreso = rawCheckout
            ? dayjs(rawCheckout).format('DD/MM/YYYY')
            : '';
          const destino = detailSearch.find((it) => it.title === 'Destino:')?.description;

          const dias = cd?.secondLine?.replace(' días', '') || '';

          return (
            <Card
              key={`cd-${i}-${j}`}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                border: '1px solid #DADADA',
                boxShadow: '0px 4px 4px rgba(0,0,0,0.25)',
                borderRadius: '16px',
                mb: 2,
                overflow: 'hidden',
                m: 2
              }}
            >
              <Grid container>
              <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{
                    backgroundColor: '#EAF3FF',
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                  }}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      gap: 2,
                      p: 2,
                      m: 2
                    }}
                  >
                    <Box
                      component="img"
                      src={cd?.image}
                      alt={`${cd?.name} Icon`}
                      sx={{
                        width: 60,
                        height: 60,
                        borderRadius: '50%',
                        objectFit: 'cover',
                      }}
                    />

                    <Typography
                      variant="h6"
                      sx={{
                        color: '#0d47a1',
                        fontWeight: 'bold',
                        m: 0,
                        overflowWrap: 'break-word',
                        wordWrap: 'break-word',
                        maxWidth: '100%',
                      }}
                    >
                      {cd?.name}
                    </Typography>
                  </CardContent>
                </Grid>

                <Grid item xs={12} md={8}>
                  <CardContent sx={{ p: 2 }}>
                    <Grid container spacing={2} alignItems="flex-start">
                      <Grid item xs={12} md={8}>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: 'bold', color: '#0d47a1', mb: 1 }}
                        >
                          {`Cobertura ${cd?.name} (De ${realRange} años)`}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                      >
                        <Box
                          component="img"
                          src={cd?.providerImage}
                          alt={cd?.provider}
                          sx={{ maxHeight: 40, maxWidth: '100%' }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2">
                          <Box component="span" sx={{ fontWeight: 'bold' }}>
                          {t('checkout.assists.exit')}
                          </Box>{' '}
                          {salida || ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2">
                          <Box component="span" sx={{ fontWeight: 'bold' }}>
                          {t('checkout.assists.return')}
                          </Box>{' '}
                          {regreso || ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2">
                          <Box component="span" sx={{ fontWeight: 'bold' }}>
                          {t('checkout.assists.destination')}
                          </Box>{' '}
                          {destino || ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2">
                          <Box component="span" sx={{ fontWeight: 'bold' }}>
                          {t('checkout.assists.days')}
                          </Box>{' '}
                          {dias}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        sx={{
                          color: '#1565c0',
                          mt: 1,
                          cursor: 'pointer',
                          textDecoration: 'none',
                          '&:hover': { textDecoration: 'underline' },
                          display: 'inline-flex',
                          alignItems: 'center',
                        }}
                        onClick={() => handleOpenCoverage(cd)}
                      >
                        <VisibilityIcon
                          sx={{
                            mr: 0.5,
                            fontSize: '1.1rem'
                          }}
                        />
                        {t('checkout.assists.viewCoverageDetails')}
                      </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Grid>
              </Grid>
            </Card>
          );
        });
      })}

      <CardCoverage
        open={openModal}
        onClose={handleCloseModal}
        rate={selectedRate}
      />
    </>
  );
};

export { getProductsDetails, getCardDetail };
