import React, { useState } from 'react';
import { Container, Grid, TextField, Typography, Alert, Snackbar } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { useClientData } from '../../context/ClientContext';
import { subscribesNewsletter } from '../../store/services/Login';

const Newsletter = (props) => {
  const [t] = useTranslation();
  const { clientData } = useClientData();
  const [email, setEmail] = useState('');
  const [messagesSnackbar, setMessagesSnackbar] = useState();
  const [loadingNewsletter, setLoadingNewsletter] = useState(false);

  function sanitizeInput(input) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(input)) {
        return '';
    }
    return input
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#x27;")
      .replace(/\//g, "&#x2F;");
  }

  const handleSubscribe = async () => {
    const sanitizeEmail = sanitizeInput(email);
    if (sanitizeEmail) {
      setLoadingNewsletter(true);
      subscribesNewsletter(clientData.credential.api.tokenNewsletter, sanitizeEmail, window.location.origin)
        .then(response => {
          setMessagesSnackbar(t('common.successSuscribes'));
        })
        .finally(() => {
          setLoadingNewsletter(false);
        });
    }
  };

  return (
    <section className='newsletter'>
      <Container>
        <Grid className='row'>
          <Typography className='title'>{t('common.suscribe')}</Typography>
          <TextField
            fullWidth
            size='small'
            placeholder={t('common.enterEmail')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <LoadingButton
            variant='contained'
            onClick={handleSubscribe}
            loading={loadingNewsletter}
          >
            {t('common.send')}
          </LoadingButton>
        </Grid>
        <Grid>
          {props.client?.newsletter?.messages?.map((message, i) => (
            <Typography key={i}>{message.item}</Typography>
          ))}
        </Grid>
      </Container>
      <Snackbar
        open={Boolean(messagesSnackbar)}
        autoHideDuration={4000}
        onClose={() => setMessagesSnackbar(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setMessagesSnackbar(null)}
          severity="success"
          sx={{
            width: '100%',
            padding: '10px'
          }}
        >
          <Typography style={{ fontSize: '1.2rem' }}>
            {messagesSnackbar}
          </Typography>
        </Alert>

      </Snackbar>
    </section>
  );
}

export default Newsletter;
