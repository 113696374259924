import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import { useTranslation } from 'react-i18next';

const AgeCardSelection = ({ ranges, onRangeSelect, selectedRange, selectedRate }) => {
  const { t } = useTranslation();
  const handleClick = (value, index) => {
    // if (previusRangeSelected(index)) {
      onRangeSelect(value);
    // }
  };

  const getCardStyle = (value, index) => {
    const isPreviusRangeSelected = true || previusRangeSelected(index);
    return {
        backgroundColor: selectedRange === value ? '#245CFF' : !isPreviusRangeSelected ? '#e7e7e7' : '#ffffff',
        color: selectedRange === value ? 'white' : '#1449E4',
        border: '1px solid #245CFF',
        cursor: isPreviusRangeSelected ? 'pointer' : 'default',
        position: 'relative',
        height: '80%',
        borderRadius:
          index === 0
            ? '8px 0 0 0'
            : index === ranges.length - 1
            ? '0 8px 8px 0'
            : '0',
      }
  };

  const renderPassengerIcon = (quantity) => {
    return quantity > 1 ? (
      <GroupIcon fontSize="small" sx={{ mr: 0.5 }} />
    ) : (
      <PersonIcon fontSize="small" sx={{ mr: 0.5 }} />
    );
  };

  const previusRangeSelected = (index) => {
    if (index > 0) {
      return selectedRate[ranges[index-1]?.range]
    }
    return true
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={0} sx={{ border: '0px solid #245CFF', borderRadius: '8px' }}>
        {ranges.map(({ range, quantity }, index) => (
          <Grid item xs={12} sm={Math.floor(12 / ranges.length)} key={range}>
            <Card className={`age-card-selection ${index !== 0 ? 'not-first' : ''}`} elevation={0}
            sx={getCardStyle(range, index)} onClick={() => handleClick(range, index)}>
              <CardContent sx={{ textAlign: 'left', padding: '10px' }}>
                <Typography variant="body1">{`De ${range.replace('-', ' a ') } ${t('results.assists.years')}`}</Typography>
                <Typography variant="body2" sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                  {renderPassengerIcon(quantity)}
                  {quantity > 1 ? `${quantity} Pasajeros` : `${quantity} Pasajero`}
                </Typography>
                {selectedRate[ranges[index]?.range] && (
                  <Box className="icon-check">
                    <CheckCircleOutlineIcon fontSize="small" />
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AgeCardSelection;
