import React, { Fragment } from 'react';
import { Avatar, Badge, Card, CardContent, CardHeader, Grid, Hidden, Icon, Typography } from '@mui/material';
import DetailDialog from './DetailDialog';
import * as HotelsDetail from './utils/HotelsDetail';
import * as TrainsDetail from './utils/TrainsDetail';
import * as FlightsDetail from './utils/FlightsDetail';
import * as AssistsDetail from './utils/AssistsDetail';
import { useTranslation } from 'react-i18next';
import { usePurchaseDetailContext } from '../../../context/PurchaseDetailContext';
import dayjs from 'dayjs';

const PurchaseDetail = (props) => {
  const { t } = useTranslation();
  const { includeComission } = usePurchaseDetailContext();

  const getProductsDetails = (products) => {
    if (props.module === 'hotels') {
      return HotelsDetail.getProductsDetails(props.purchaseDetail);
    } else if (props.module === 'trains') {
      return TrainsDetail.getProductsDetails(props.purchaseDetail);
    } else if (props.module === 'flights') {
      return FlightsDetail.getProductsDetails(props.purchaseDetail);
    } else if (props.module === 'assists') {
      return AssistsDetail.getProductsDetails(props.purchaseDetail);
    }
  }

  const getPriceDetails = () => {
    if (props.module === 'trains') {
      return TrainsDetail.getPriceDetails(props.purchaseDetail, includeComission, false);
    } else if (props.module === 'flights') {
      return FlightsDetail.getPriceDetails(props.purchaseDetail);
    }

    return [
      {
        title: 'total',
        value: props.purchaseDetail?.totalAmount,
        classname: 'strong amount',
        showTax: true
      }
    ];
  }

  return (
    <Card className='purchase-card'>
      <CardHeader
        avatar={
          <Avatar className='avatar-purchase-detail'>
            <Badge
              badgeContent={props.purchaseDetail?.priceDetail?.length || 0}
              color='primary'
              overlap='circular'
            >
              <Icon>{props.purchaseDetail?.icon}</Icon>
            </Badge>
          </Avatar>
        }
        action={props.products &&
          <DetailDialog
            icon='expand_more'
            purchaseDetail={props.purchaseDetail}
            products={props.products}
            module={props.module}
          />
        }
        title={
          <Typography className='title-card-header'>
            {t('checkout.common.purchaseDetailTitle')}
          </Typography>
        }
        subheader={
          <Typography noWrap className='title-card-subheader' >
					{props.module === 'assists'
          ? `${dayjs(props.purchaseDetail.checkin).format('ddd DD-MM')} al ${dayjs(props.purchaseDetail.checkout).format('ddd DD-MM')}`
          : props.purchaseDetail?.subtitle
          }
          </Typography>
        }
      />

      <Hidden smDown>
        <CardContent>
          {getProductsDetails(props.purchaseDetail?.priceDetail)}
        </CardContent>
      </Hidden>

      <Grid container className="price-details-container row">
  {getPriceDetails().map((elem, i) => (
    <Fragment key={i}>
      {elem.value && parseInt(elem.value.replaceAll('.', '')) > 0 && (
        <>
          <Grid item xs={6} className="left">
            {/* Etiqueta (ej. "Total") */}
            <Typography
              className={elem.classname}
              sx={{
                // Si es assists, azul. Si no, color por defecto.
                color: props.module === 'assists' ? '#1449e4 !important ' : 'inherit',
              }}
            >
              {elem.noTranslate ? elem.title : t(`checkout.common.${elem.title}`)}
            </Typography>
            {elem.showTax && Boolean(props.purchaseDetail?.taxAmount) && props.purchaseDetail?.taxAmount > 0 && (
              <Typography className="taxes">
                {t('checkout.common.taxesIncluded')} {props.purchaseDetail?.currency}{' '}
                {props.purchaseDetail?.taxAmount}
              </Typography>
            )}
          </Grid>

          <Grid item xs={6} className="right">
            {/* Valor (ej. "USD 24") */}
            <Typography
              className={`text-right ${elem.classname}`}
              sx={{
                color: props.module === 'assists' ? '#1449e4 !important'  : 'inherit',
              }}
            >
              {elem.parentehsis && '('}
              {props.purchaseDetail?.currency} {elem.value}
              {elem.parentehsis && ')'}
            </Typography>
          </Grid>
        </>
      )}
    </Fragment>
  ))}
</Grid>

    </Card>
  )
}

export default PurchaseDetail;
