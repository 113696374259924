import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Box, Stack, Button, Typography, IconButton, Grid, Card} from '@mui/material';
import { compareCoverageAction } from '../../../store/actions';
import { CloseOutlined, Visibility, PictureAsPdf, Print } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { useClientData } from '../../../context/ClientContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ResultsCard from './ResultsCard';
import CardCoverage from './CardCoverage';
import { PDFComparation } from './PDFComparation';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export function ComparationResultsView({
    compareData,
    comparationResults = [],
    onBack,
    compareList,
    onSelectRate,
    token,
    ranges,
    dataForm,
    handleAdd,
    optionsSearch,
    results,
    clearCompareCoverageAction,
    onClose = () => {},
    handleRemove,
    ...props
 }) {
    const { t } = useTranslation();
    const [comparation, setComparation] = useState(compareData);
    const { currentProduct } = useClientData();
    const [select, setSelect] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      setComparation(compareData);
    }, [compareData]);

    useEffect(() => {
      handleCompare(compareList);

      setSelect(false)
    }, [select]);

    const handleDeleteProduct = (rateId) => {
      setComparation((prev) => {
        const newComparison = { ...prev };
        const productKeys = Object.keys(newComparison).filter((k) => k !== "0");

        productKeys.forEach((k) => {
          const currentRateId = newComparison[k][3];
          if (currentRateId === rateId) {
            delete newComparison[k];
          }
        });
        const remainingKeys = Object.keys(newComparison).filter((k) => k !== "0");
        if (remainingKeys.length === 0) {
          onBack();
        }

        return newComparison;
      });

      if (handleRemove) {
        handleRemove?.(rateId);
      }

    };

    const handleSelectPlan = (rateId) => {
      const planFromList = compareList.find(
        (item) => String(item.rateId) === String(rateId)
      );
      if (!planFromList) {
        return;
      }

      if (onSelectRate) {
        onSelectRate(planFromList);
      }

      onBack();
    };

    const coverageTitles = comparation['0'] || [];
    const productKeys = Object.keys(comparation).filter((k) => k !== '0');

    const productData = productKeys.map((k) => {
      const arr = comparation[k];
      return {
        name: arr[0],
        image: arr[1],
        token: arr[2],
        rateId: arr[3],
        isOffer: arr[4],
        coverageValues: arr.slice(5),
      };
    });

    const handleCompare = async (listToCompare) => {
      if (!currentProduct || !currentProduct.parameters) return;

      const comparadorUrl = currentProduct.parameters.comparador_url;
      const comparadorUsername = currentProduct.parameters.comparador_username;
      const comparadorPassword = currentProduct.parameters.comparador_password;

      try {
        const requestBody = {
          username: comparadorUsername,
          password: comparadorPassword,
        };

        const response = await fetch(comparadorUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) return;

        const tokenData = await response.json();
        const apiToken = tokenData.access;

        const data = listToCompare.map((product) => ({
          product_name: product.product,
          token_product: apiToken,
          rate_id: product.rateId?.toString() || "",
          is_offer: product.isOffer ? "1" : "0",
          url_image: product.mediaImage,
          coverages: product.coverage || [],
        }));

        await dispatch(compareCoverageAction(apiToken, data));

        clearCompareCoverageAction();
      } catch (error) {
        // console.error("Error en handleCompare:", error);
      }
    };

    const handleAddRate = (rate) => {
      handleAdd(rate, true);
      setSelect(true)
    };

    const handlePrintButtonClick = (url) => {
      const printWindow = window.open(url, '_blank');
      printWindow.onload = () => {
        printWindow.print();
      };
    };

    const matchingResult = results.find(
      (item) => item.range === ranges
    );

    if (!matchingResult) {
      return null;
    }

    const { rates } = matchingResult;

    const additionalRate = rates.find(
      (rate) => !compareList.some(
        (comparedItem) => comparedItem.rateId === rate.rateId
      )
    );

    if (!additionalRate) {
      return null;
    }

    return (
      <Box sx={{ p: 2 }} className='comparation-component comparation-component-assist'>
       <Box className="comparation_view-title" sx={{ display: 'flex', alignItems: 'center', gap: 1,  mb: 2,   }} >
        <IconButton className='icon-comparation' onClick={onBack} >
            <ArrowBackIcon />
        </IconButton>
        <Typography className="title">
          {t('results.assists.compareCoverages')} ({t('results.assists.of')} {ranges.replace('-', ' a ')} {t('results.assists.years')})
        </Typography>
        </Box>

        <Grid className="comparation_view-table_container" container columns={24} spacing={2} >
          <Grid item xs={7}>
            <Box className="comparation_view-actions-buttons">
              <PDFDownloadLink
                  document={
                    <PDFComparation
                      client={props.client}
                      title={`Comparar coberturas (De ${ranges.replace('-', ' a ')} años)`}
                      coverageTitles={coverageTitles}
                      resultsToCompare={productData}
                      dataForm={dataForm}
                      optionsSearch={optionsSearch}
                      compareList={compareList}
                    />
                  }
                  //fileName={${t('results.hotels.compareHotelsInDestination', { destination: dataForm?.destination?.label, interpolation: { escapeValue: false } })}.pdf}
                  style={{ textDecoration: 'none' }}
                >
                  {({ url, loading, error }) => (
                    <>
                      <Button className='button row' disabled={loading || error}>
                        <PictureAsPdf />{t('results.assists.download')}
                      </Button>
                      <Button
                        className='button row'
                        disabled={loading || error}
                        onClick={(e) => {
                          e.preventDefault();
                          handlePrintButtonClick(url);
                        }}
                      >
                        <Print />{t('results.assists.print')}
                      </Button>
                    </>
                  )}
              </PDFDownloadLink>
            </Box>
            <TableOfCoverages coverageTitles={coverageTitles} />
          </Grid>

          {/* DERECHA: “cards” grandes, una por cada plan */}
          {productData.map((plan, idx) => (
            <CardLarge
              key={idx}
              plan={plan}
              compareList={compareList}
              coverageTitles={coverageTitles}
              onRemove={() => handleDeleteProduct(plan.rateId)}
              onSelectPlan={handleSelectPlan}/>
            ))}
            {/* {Object.keys(comparation).length < 5 && additionalRate && (
              <Grid item xs={5} sx={{height: 'auto'}} className='col-add-more-coverages' >
                <Card sx={{p:2, borderRadius: 2}} elevation={0} >
                  <Typography className='title-add-more-coverages'>
                  {t('results.assists.addToppings')}
                  </Typography>
                  <ResultsCard
                    rate={additionalRate}
                    onSelect={handleAddRate}
                    isCompareView
                  />
                </Card>
              </Grid>
            )} */}
            
            </Grid>
        </Box>
    );
  }

  function TableOfCoverages({ coverageTitles }) {
    return (
      <Box className='coverages-comparation-names' >
          {coverageTitles.map((title, rowIndex) => {
            if (title === '-') return null;
              const capitalizedTitle =
              title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();

            return (
                <Box key={rowIndex} className='coverage_comparation coverage-name'>{capitalizedTitle}</Box>
            );
          })}
      </Box>
    );
  }

  function CardLarge({ plan, coverageTitles, onRemove, compareList, onSelectPlan }) {
    const { t } = useTranslation();

    const handleSelect = () => {
        onSelectPlan?.(plan.rateId);
      };

    const planFromList = compareList.find(
      (item) => String(item.rateId) === String(plan.rateId)
    );

    const {
      totalValuePvp = 0,
      totalPromotion = 0,
      individualValuePvp = 0,
      individualPromotion = 0,
      pricePax = [],
    } = planFromList || {};

    const numberOfPax = pricePax.length;

    const labelPeople = numberOfPax === 1 ? 'Persona' : 'Personas';

    const formatMoney = (val) => `USD ${Math.round(val)}`;

    return (
      <Grid item xs={4} className='comparation_view-card_large' >
        <Box className="coverage_comparation-item">
          {/* Botón de cierre (X) */}
          <IconButton className='buttonClose' onClick={() => onRemove(plan.rateId)} >
            <CloseOutlined fontSize="small" />
          </IconButton>

          {/* Cabecera del card: imagen + nombre */}
          <Box className="coverage_comparation-header" sx={{ p: 2, gap: 1, }} >
            <img src={plan.image} alt={plan.name} className='coverage_comparation-image' />
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }} className='coverage_comparation-title'>
              {plan.name}
            </Typography>
          </Box>

          {/* Lista de coverageValues en vertical */}
          <Box sx={{ position: 'relative', mt: -2, pt: 1.5, }} >
            {coverageTitles.map((coverage, i) => {
              if (coverage === '-') return null;
              const coverageValue = plan.coverageValues[i] ?? '-';
              return (
                <Box className="coverage_comparation coverage_comparation-row-item" key={i}>
                  <Typography variant="body2" sx={{ color: '#333' }}>
                    {coverageValue}
                  </Typography>
                </Box>
              );
            })}
          </Box>

          <Box className="coverage_comparation-price" sx={{ p: 2 }}>
            {/* Título */}
            <Typography
              className="coverage_comparation-price-title"
              variant="subtitle1"
              sx={{ mb: 1 }}
            >
              {t('results.assists.totalCost')}
            </Typography>

            {/* Condición para "Antes" y precio promocional */}
            {totalPromotion > 0 ? (
              <Box sx={{ mb: 1 }}>
                <Typography component="span" sx={{ color: '#f33', marginRight: 0.5 }}>
                {t('results.assists.before')}
                </Typography>
                <Typography className="coverage_comparation-totalpvp" sx={{ color: '#f33', marginRight: 8 }} noWrap component="span">
                  {formatMoney(totalValuePvp)}
                </Typography>

                {/* Precio promocional actual en azul (o el estilo que desees) */}
                <Typography className="coverage_comparation-total" noWrap component="span" >
                  {formatMoney(totalPromotion)}
                </Typography>
              </Box>
            ) : (
              <Box sx={{ mb: 1 }}>
                <Typography className="coverage_comparation-total" noWrap component="span">
                  {formatMoney(totalValuePvp)}
                </Typography>
              </Box>
            )}
            <Typography variant="body2" sx={{ color: '#003da5', mb: 1 }}>
              {numberOfPax} {labelPeople}
            </Typography>

            {/* Botón en rojo */}
            <Button
              variant='contained'
              fullWidth
              color="secondary"
              className='comparation_btn-select'
              onClick={handleSelect}
            >
              {t('results.assists.select')}
            </Button>
          </Box>

        </Box>
      </Grid>
    );
  }

function ComparationView({
  comparationResults = [],
  discountCode,
  localCurrency,
  compareCoverageAction,
  clearCompareCoverageAction,
  onToggleCompare = () => {},

  open = true,
  onClose = () => {},
  selectedRates = [],
  ranges,
  onClearCompareList = () => {},
  dataForm,
  ...props
}) {
  const { t } = useTranslation();
  const containerRef = useRef();
  const [comparation, setComparation] = useState(selectedRates);
  const [isHorizontal, setIsHorizontal] = useState(false);

  const [showCoverageModal, setShowCoverageModal] = useState(false);
  const [coverageRate, setCoverageRate] = useState(null);

  const { currentProduct } = useClientData();

  useEffect(() => {
    setComparation(selectedRates);
  }, [selectedRates]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResize = () => {
    const height = containerRef.current?.clientHeight || 0;
    setIsHorizontal(height < 300);
  };

  const handleDeleteProduct = (rateId) => {
    const updated = comparation.filter((item) => item.rateId !== rateId);
    setComparation(updated);
    selectedRates = updated;

    compareCoverageAction({ rateId }, false);

    const rateToRemove = comparation.find((it) => it.rateId === rateId);
    if (rateToRemove) {
      onToggleCompare(rateToRemove, false);
    }
  };

 const handleCompare = async () => {
  if (!currentProduct || !currentProduct.parameters) {
    return;
  }

  const comparadorUrl = currentProduct.parameters.comparador_url;
  const comparadorUsername = currentProduct.parameters.comparador_username;
  const comparadorPassword = currentProduct.parameters.comparador_password;

  try {
    const requestBody = {
      username: comparadorUsername,
      password: comparadorPassword
    };

    const response = await fetch(comparadorUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      // console.error("Error al obtener token:", response.statusText);
      return;
    }

    const tokenData = await response.json();
    const apiToken = tokenData.access;

    const data = comparation.map((product) => ({
      product_name: product.product,
      token_product: apiToken,
      rate_id: product.rateId?.toString() || "",
      is_offer: product.isOffer ? "1" : "0",
      url_image: product.mediaImage,
      coverages: product.coverage || [],
    }));

    await compareCoverageAction(apiToken, data);

    onClose();
    setComparation([]);
    clearCompareCoverageAction();

  } catch (error) {
    // console.error("Error en handleCompare:", error);
  }
  };

  const handleCloseCoverage = () => {
    setShowCoverageModal(false);
    setCoverageRate(null);
  };

  const handleOpenCoverage = (rate) => {
    setCoverageRate(rate);
    setShowCoverageModal(true);
  };

  if (!open || comparation.length === 0) {
    return null;
  }

  const renderCoverageCard = (item, idx) => {
    const { name, mediaImage, coverage, prices } = item;
    const rawPrice = prices?.[0]?.totalPromotion ?? prices?.[0]?.totalValuePVP ?? 0;
    const priceInt = Math.round(rawPrice);

    const coverName0 = coverage?.[0]?.name
      ? coverage[0].name.charAt(0).toUpperCase() + coverage[0].name.slice(1).toLowerCase()
      : 'Cobertura';
    const coverValue0 = coverage?.[0]?.value ?? 'N/A';

    const coverName1 = coverage?.[1]?.name
      ? coverage[1].name.charAt(0).toUpperCase() + coverage[1].name.slice(1).toLowerCase()
      : 'Detalle';
    const coverValue1 = coverage?.[1]?.value ?? 'N/A';

    return (
      <Grid item xs={12} md={3} key={item.rateId || idx} className='comparation_view-coverage_grid-item' >
        <Card className='comparation_view-coverage_card-item' >
          {/* Botón de cerrar */}
          <IconButton
            onClick={() => handleDeleteProduct(item.rateId)}
            sx={{
              overflow: 'visible',
              position: 'absolute',
              top: -10,
              right: -10,
              backgroundColor: '#fff',
              borderRadius: '50%',
              boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
              width: 32,
              height: 32,
              '&:hover': { backgroundColor: '#f5f5f5' },
            }}
          >
            <CloseOutlined fontSize="small" />
          </IconButton>

          {/* Encabezado */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: '100%', mb: 1 }}
          >
            {/* Ícono + nombre */}
            <Stack direction="row" spacing={1} alignItems="center">
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  wordBreak: 'break-word',
                }}
              >
                <img
                  src={mediaImage}
                  alt="Rate Icon"
                  style={{
                    borderRadius: '50%',
                    objectFit: 'cover',
                  }}
                />
              </Box>
              <Typography variant="subtitle1"
                sx={{
                  fontWeight: 900,
                  color: '#0f0d0d',
                  overflow: 'hidden',
                  maxWidth: 150,
                }}
            >
              {name
                ? name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
                : 'Plan'}
            </Typography>

            </Stack>

            {/* Precio */}
            <Stack direction="column" alignItems="center" sx={{minWidth: '30%'}}>
              <Typography variant="body2" sx={{ color: '#666', fontWeight: 400, mb: 0.2 }}>
              {t('results.assists.totalCost')}
              </Typography>
              <Typography variant="h6" sx={{ color: '#003DA5', fontWeight: 700 }}>
              {t('results.assists.exchangeRate')} {priceInt}
              </Typography>
            </Stack>
          </Stack>

          {/* Coberturas */}
          <Box className="comparison_coberturas"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="caption"
              sx={{
                color: 'rgba(96, 96, 96, 1)',
                fontWeight: 400,
                textAlign: 'center',
                display: 'inline-block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
              }}
            >
              {coverName0}: {coverValue0}
            </Typography>

            <Typography
              variant="caption"
              sx={{
                color: 'rgba(96, 96, 96, 1)',
                fontWeight: 400,
                textAlign: 'center',
                display: 'inline-block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
              }}
            >
              {coverName1}: {coverValue1}
            </Typography>
          </Box>

          {/* Botón “Ver cobertura” */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={0.5}
            sx={{ mt: 1, cursor: 'pointer' }}
          >
            <Visibility sx={{ fontSize: 14, color: '#245CFF' }} />
            <Typography
              variant="caption"
              sx={{
                textDecoration: 'underline',
                color: '#245CFF',
                fontWeight: 1000,

              }}
              onClick={() => handleOpenCoverage(item)}
            >
              {t('results.assists.seeCoverage')}
            </Typography>
          </Stack>
        </Card>
      </Grid>
    );
  };

  return (
    <Box className="comparation_view-main-box" ref={containerRef} sx={{ py: 2, px: 3}} >
      <Stack direction="column" spacing={1} sx={{ width: '100%' }}>
        {/* Título + Botones */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          <Typography variant="h6" sx={{ color: '#fff' }}>
          {t('results.assists.compareCoverages')} ({t('results.assists.of')} {ranges.replace('-', ' a ')} {t('results.assists.years')})
          </Typography>

          <Stack direction="row" spacing={1} alignItems="center">
            <Button
              className='comparation_view-button-clear'
              onClick={() => { setComparation([]); onClearCompareList(); }}
              startIcon={<CloseIcon sx={{ fontSize: 16 }} />}
              sx={{
                color: '#fff',
                textTransform: 'none',
                fontWeight: 400,
                fontSize: '0.875rem',
                '&:hover': {
                  backgroundColor: 'transparent',
                  textDecoration: 'underline',
                },
              }}
            >
              {t('results.assists.cleanCompare')}
            </Button>

           {/* Botón “Comparar” -> Llamamos a handleCompare */}
           <Button
              className='comparation_view-button-compare'
              variant="outlined" onClick={handleCompare}
              disabled={comparation.length < 2} >
                {t('results.assists.compare')}
            </Button>

          </Stack>
        </Stack>

        {/* Tarjetas de la comparación */}
        <Grid container spacing={2} className='comparation_view-grid-coverage-cards'>
          {comparation.map(renderCoverageCard)}
        </Grid>
      </Stack>

      <CardCoverage
        open={showCoverageModal}
        onClose={handleCloseCoverage}
        rate={coverageRate}
      />
    </Box>
  );
}

const mapStateToProps = (state) => ({
  comparationResults: state.assistsReducer?.compareResults,
  discountCode: state.someReducer?.discountCode,
  localCurrency: state.someReducer?.localCurrency,
});

const mapDispatchToProps = (dispatch) => ({
  compareCoverageAction: (token, data) => dispatch(compareCoverageAction(token, data)),
  clearCompareCoverageAction: () => dispatch({ type: 'CLEAR_COMPARE_COVERAGE' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComparationView);
